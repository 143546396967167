<template>
  <div class="orderList">
    <frame :top1="top1" :top2="top2">
      <tabbar :tabbar="tabbar" @cuts="cut"></tabbar>
      <search @search="search"></search>
      <lists
        :titles="titles"
        :flag="flag"
        :contentC="datas"
        @add="refresh()"
        @del="refresh()"
      ></lists>
      <!-- <page :num='num'></page> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[3, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="titlePage"
      >
      </el-pagination>
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";
import lists from "./componList/List.vue";
import tabbar from "./componList/TabbarList.vue";
import search from "./componList/Search.vue";
import page from "../commodity/componList/Page.vue";
export default {
  name: "orderList",
  data() {
    return {
      top1: "5-1",
      top2: ["5"],
      titles: [
        "订单编号",
        "提交时间",
        "所属支行",
        "所属门店",
        "订单金额",
        "支付方式",
        "配送方式",
        "订单状态",
        "操作",
      ],
      flag: 1,
      datas: null,
      currentPage4: 1,
      titlePage: 1,
      currentpage: 1,
      set: "whole",
      pageSize: 3,
      tabbar: [
        "全部订单",
        "待付款",
        "待发货",
        "已发货",
        "已完成",
        "已关闭",
        "申请退款",
        "已退款",
      ],
      num: 100,
    };
  },
  components: {
    frame,
    lists,
    tabbar,
    search,
    page,
  },
  created() {
    let token = this.$storage.getLocal("token");
    this.$request
      .selOrderList({
        su_id: "",

        token,
        name: "",
        order: "",
        storeid: "",
        page: 1,
        limit: 3,
        status: "whole",
        time: "",
        type: this.$storage.getLocal("type"),
      })
      .then((res) => {
        if (res.code == 0) {
          this.datas = res.data.data;
          this.titlePage = res.data.count;
        }
      });
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);

      this.pageSize = val;
      let token = this.$storage.getLocal("token");
      this.$request
        .selOrderList({
          su_id: "",

          token,
          name: "",
          order: "",
          storeid: "",
          page: 1,
          limit: this.pageSize,
          status: this.set,
          time: "",
          type: this.$storage.getLocal("type"),
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            // this.num = res.data.count
          }
        });
    },

    handleCurrentChange(val) {
      // this.pageSize = val;
      let token = this.$storage.getLocal("token");
      this.$request
        .selOrderList({
          su_id: "",

          token,
          name: "",
          order: "",
          storeid: "",
          page: val,
          limit: this.pageSize,
          status: this.set,
          time: "",
          type: this.$storage.getLocal("type"),
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            // this.num = res.data.count
          }
        });
    },
    refresh() {
      let token = this.$storage.getLocal("token");
      this.$request
        .selOrderList({
          su_id: "",

          token,
          name: "",
          order: "",
          storeid: "",
          page: 1,
          limit: this.pageSize,
          status: 1,
          time: "",
          type: this.$storage.getLocal("type"),
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            // this.num = res.data.cunt
          }
        });
    },
    cut(item) {
      let set = "whole";
      if (item == "待付款") {
        set = 0;
      } else if (item == "待发货") {
        set = 1;
      } else if (item == "已发货") {
        set = 2;
      } else if (item == "已完成") {
        set = 4;
      } else if (item == "已关闭") {
        set = 5;
      } else if (item == "申请退款") {
        set = 6;
      } else if (item == "已退款") {
        set = 7;
      } else if (item == "全部订单") {
        set = "whole";
      }
      this.set = set;
      let token = this.$storage.getLocal("token");
      this.$request
        .selOrderList({
          su_id: "",

          token,
          name: "",
          order: "",
          storeid: "",
          page: 1,
          limit: this.pageSize,
          status: set,
          time: "",
          type: this.$storage.getLocal("type"),
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    search(item) {
      console.log("111", item);
      let newTime = "";
      if (item.date) {
        newTime =
          item.date.getFullYear() +
          "-" +
          (item.date.getMonth() + 1) +
          "-" +
          item.date.getDate();
      }
      let token = this.$storage.getLocal("token");
      this.$request
        .selOrderList({
          token,
          name: item.phoneName,
          order: item.goodsName,
          storeid: item.storeid,
          su_id: item.su_id,
          page: 1,
          limit: this.pageSize,
          status: "whole",
          time: newTime,
          type: this.$storage.getLocal("type"),
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            // this.num = res.data.cunt
          }
        });
    },
  },
};
</script>

<style scoped="scoped">
</style>
