<template>
  <div class="content">
    <div class="liubai"><Icon type="ios-search" />筛选查询</div>

    <div class="search">
      <div class="input">
        <div class="text">请输入：</div>
        <Input
          v-model="goodsName"
          placeholder="请输入编号/货号"
          clearable
          style="width: 180px"
        />
      </div>

      <div class="input">
        <div class="text">请输入：</div>
        <Input
          v-model="phoneName"
          placeholder="请输入手机号/姓名"
          clearable
          style="width: 180px"
        />
      </div>

      <div class="shuai">
        <div style="width: 80px; margin-top: 15px">选择门店：</div>
        <Select placeholder="请选择" class="sel" v-model="storeid">
          <Option
            :value="item.storeid"
            v-for="(item, index) in selectp"
            :key="index"
            @click.native="storeidType(item.storeid)"
            >{{ item.s_name }}</Option
          >
        </Select>
      </div>

      <div class="shuai">
        <!-- <div class="selname" ></div> -->
        <div style="width: 80px; margin-top: 15px">所属支行：</div>
        <!-- <Select placeholder="请选择" class="sel" v-model="storeid">
			<Option
			  :value="item.storeid"
			  v-for="(item, index) in selectp"
			  :key="index"
			  @click.native="storeidType(item.storeid)"
			  >{{ item.s_name }}</Option
			>
		  </Select> -->

        <Select
          class="sel"
          placeholder="请选择"
          :label-in-value="true"
          v-model="bank_id"
        >
          <Option
            :value="item.id"
            v-for="(item, index) in bankList"
            :key="index"
            >{{ item.s_name }}</Option
          >
        </Select>
      </div>
    </div>
    <div class="search">
      <div class="input" style="margin-left: 25px;">
        <Form :model="formItem" :label-width="80">
          <FormItem label="选择时间">
            <Row>
              <Col span="21">
                <DatePicker
                  type="date"
                  placeholder="请选择时间"
                  v-model="formItem.date"
                ></DatePicker>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>

      <div class="input">
        <Button type="success" @click="search()">搜索</Button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "search",
  data() {
    return {
      goodsName: "",
      selectp: "",
      phoneName: "",
      storeid: "",
      bankList: [],
      bank_id: "", //支行id 餐费
      formItem: {
        date: "",
      },
    };
  },
  created() {
    let token = this.$storage.getLocal("token");
    let login_type = this.$storage.getLocal("type");

    if (login_type == "1") {
      this.$request.getMerchantData({ token }).then((r) => {
        if (r.code == 0) {
          this.selects = r.data;
          console.log(r);
        }
      });
    } else if (login_type == "2") {
      // 支行列表
      this.$request.subbranchSelData({ token }).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          console.log(this.bankList);
          // return
          this.bankList = res.data;
          console.log(this.bankList);
        }
      });

      this.$request
        .getStoreDataList({
          token,
          pstoreid: "",
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    }
  },
  methods: {
    //选中支行
    //   selectBank(e) {
    // 	console.log(e);
    // 	this.formValidate.bank_id = e.value;
    // 	this.formValidate.bank_name = e.label;
    //   },
    storeidType(e) {
      console.log(e);
      this.storeid = e;
    },
    search() {
      this.$emit("search", {
        goodsName: this.goodsName,
        phoneName: this.phoneName,
        date: this.formItem.date,
        storeid: this.storeid,
        su_id: this.bank_id,
      });
    },
  },
};
</script>
  
  <style scoped="scoped">
.search {
  /* padding-left: 200px; */
  margin-left: 200px;
  display: flex;
  /* justify-content: center; */
  text-align: left;
}

.input {
  /* width: 80%; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-left: 50px;
  padding: 10px 0 10px 0;
}

.liubai {
  width: 100%;
  height: 40px;
  background-color: #f3f3f3;
  font-size: 15px;
  text-align: left;
  padding-left: 220px;
  line-height: 40px;
}
.shuai {
  display: flex;
  margin-left: 50px;
  /* border:1px solid black */
}
.selname {
  width: 200px;
  border: 1px solid black;
}
.sel {
  /* margin-left: 10px; */
  margin-top: 10px;
  width: 180px;
}
</style>
  