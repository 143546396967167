<template>
  <div class="list">
    <el-dialog title="详情" :visible.sync="dialogVisible" width="70%">
      <span>
        <table
          style="
            border-collapse: collapse;
            height: 250px;
            border: 1px solid gainsboro;
          "
        >
              
          <tr style="border-bottom: 1px solid gainsboro">
               
            <td rowspan="5;" style="width: 500px">
              <tr>
                <div
                  v-for="(item1, index1) in orderGoodsList"
                  :key="index1"
                  style="
                    width: 400px;
                    height: 100px;
                    margin-left: 40px;
                    display: flex;
                  "
                >
                  <div style="width: 30%; height: 100%">
                    <img
                      style="width: 100%; height: 100%"
                      src="https://multimerchant.yinheyun.com.cn/uploads/20211022/e3e598c1a87f85b663b2e5d9eb5a5d86.jpg"
                    />
                  </div>
                  <div>
                    <div>{{ item1.gname }}</div>
                    <div
                      style="
                        width: 100%;
                        margin-right: 250px;
                        margin-top: 35px;
                        display: flex;
                        justify-content: space-between;
                        padding: 4px;
                      "
                    >
                      <div>￥{{ item1.selling_price }}</div>
                      <div>x{{ item1.number }}</div>
                    </div>
                  </div>
                </div>
              </tr>
              <!-- <tr>电话:</tr> -->
              <tr></tr>
            </td>
                  
            <td
              style="width: 100px; text-align: right; background-color: #f5f5f5"
            >
              用户名称：
            </td>
                  
            <td style="width: 300px">{{ orderDetail.user }}</td>
                  
            <td style="width: 100px;  background-color: #f5f5f5">订单号：</td>
                  
            <td style="width: 300px">{{ orderDetail.order_sn }}</td>
                
          </tr>
              
          <tr style="border-bottom: 1px solid gainsboro">
                  <!-- <td  rowspan=3>111</td> -->
                  
            <td
              style="width: 100px; text-align: right; background-color: #f5f5f5"
            >
              手机号：
            </td>
                  
            <td style="width: 300px">{{ orderDetail.tel }}</td>
                  
            <td style="width: 100px;  background-color: #f5f5f5">支付来源：</td>
                  
            <td style="width: 300px">{{ orderDetail.pay_type }}</td>
                
          </tr>
              
          <tr style="border-bottom: 1px solid gainsboro">
                  <!-- <td  rowspan=3>111</td> -->
                  
            <td
              style="width: 100px; text-align: right; background-color: #f5f5f5"
            >
              所在地：
            </td>
                  
            <td style="width: 300px">{{ orderDetail.province }}</td>
            <!--       <td style="width:300px" >女</td> -->
                  
            <td style="width: 100px;  background-color: #f5f5f5">下单时间：</td>
                  
            <td style="width: 300px">{{ orderDetail.pay_time }}</td>
                
          </tr>
              
          <tr>
                  <!-- <td  rowspan=3>111</td> -->
                  
            <td
              style="width: 100px; text-align: right; background-color: #f5f5f5"
            >
              具体地址：
            </td>
                  
            <td style="width: 300px">{{ orderDetail.address }}</td>
                  
            <td style="width: 100px;  background-color: #f5f5f5">配送方式：</td>
                  
            <td style="width: 300px">{{ orderDetail.order_type }}</td>
                
          </tr>
              
          <tr style="border-top: 1px solid gainsboro">
                  <!-- <td  rowspan=3>111</td> -->
                  
            <td
              style="width: 100px; text-align: right; background-color: #f5f5f5"
            >
              商品数量：
            </td>
                  
            <td style="width: 300px">{{ orderDetail.goods_amount }}</td>
                  
            <td style="width: 100px;  background-color: #f5f5f5">商品价格：</td>
                  
            <td style="width: 300px">{{ orderDetail.pay_fee }}</td>
                
          </tr>
            
        </table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="order_status == 1"
          @click="fahuo(orderDetail.order_sn)"
          >发 货</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="填写物流信息" :visible.sync="dialogVisibles" width="70%">
      <span>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="选择物流公司：" label-width="120px">
            <el-select v-model="form.express_name" placeholder="请选择物流公司">
              <el-option
                label="韵达速递"
                value="yunda"
                img="http://static.showapi.com/app2/img/expImg/yunda.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/yunda.jpg"
                  style="width: 80px; height: 20px"
                />
                韵达速递</el-option
              >
              <el-option
                label="顺丰速运"
                value="shunfeng"
                img="http://static.showapi.com/app2/img/expImg/shunfeng.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/shunfeng.jpg"
                  style="width: 80px; height: 20px"
                />
                顺丰速运</el-option
              >
              <el-option
                label="邮政快递"
                value="gnxb"
                img="http://static.showapi.com/app2/img/expImg/pingyou.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/pingyou.jpg"
                  style="width: 80px; height: 20px"
                />
                邮政快递</el-option
              >
              <el-option
                label="德邦快递"
                value="debang"
                img="http://static.showapi.com/app2/img/expImg/dbwl_logo.gif"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/dbwl_logo.gif"
                  style="width: 80px; height: 20px"
                />
                德邦快递</el-option
              >
              <el-option
                label="中通快递"
                value="zhongtong"
                img="http://static.showapi.com/app2/img/expImg/zto.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/zto.jpg"
                  style="width: 80px; height: 20px"
                />
                中通快递</el-option
              >
              <el-option
                label="圆通速递"
                value="yuantong"
                img="http://static.showapi.com/app2/img/expImg/yuantong.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/yuantong.jpg"
                  style="width: 80px; height: 20px"
                />
                圆通速递</el-option
              >
              <el-option
                label="天天快递"
                value="tiantian"
                img="http://static.showapi.com/app2/img/expImg/tt.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/tt.jpg"
                  style="width: 80px; height: 20px"
                />
                天天快递</el-option
              >
              <el-option
                label="申通快递"
                value="shentong"
                img="http://static.showapi.com/app2/img/expImg/shentong.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/shentong.jpg"
                  style="width: 80px; height: 20px"
                />
                申通快递</el-option
              >
              <el-option
                label="京东快递"
                value="jingdong"
                img="http://static.showapi.com/app2/img/expImg/jingdong.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/jingdong.jpg"
                  style="width: 80px; height: 20px"
                />
                京东快递</el-option
              >
              <el-option
                label="百世汇通"
                value="huitong"
                img="http://static.showapi.com/app2/img/expImg/ht.jpg"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/ht.jpg"
                  style="width: 80px; height: 20px"
                />
                百世汇通</el-option
              >
              <el-option
                label="宅急送"
                value="zhaijisong"
                img="http://static.showapi.com/app2/img/expImg/zjs_logo.gif"
                ><img
                  src="http://static.showapi.com/app2/img/expImg/zjs_logo.gif"
                  style="width: 80px; height: 20px"
                />
                宅急送</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="输入快递单号：" label-width="120px">
            <el-input
              v-model="form.express_sn"
              style="width: 260px"
              placeholder="请输入快递单号"
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="order_status == 1"
          @click="fahuos(orderDetail.order_sn)"
          >确认发货</el-button
        >
      </span>
    </el-dialog>

    <Modal v-model="modal1" title="商品审核" @on-ok="ok">
      <div v-if="SHlist.length > 0">
        <table>
          <tr>
            <td class="titNa">审核时间</td>
            <td class="titNa">审核人员</td>
            <td class="titNa">审核结果</td>
            <td class="titNa">反馈详情</td>
          </tr>
          <tr v-for="(item9, index9) in SHlist" :key="index9">
            <td class="conBo">{{ item9.ctime }}</td>
            <td class="conBo">{{ item9.admin_name }}</td>
            <td class="conBo">{{ item9.status == 1 ? "通过" : "未通过" }}</td>
            <td class="conBo">
              {{ item9.content == "" ? "无" : item9.content }}
            </td>
          </tr>
        </table>
        <Button v-if="state == 2" type="success" @click="repetition()"
          >重新提交审核</Button
        >
      </div>
      <div v-if="SHlist.length <= 0">
        <table>
          <tr>
            <td class="titNa">审核时间</td>
            <td class="titNa">审核人员</td>
            <td class="titNa">审核结果</td>
            <td class="titNa">反馈详情</td>
          </tr>
          <tr>
            <td class="conBo">暂无审核数据</td>
            <td class="conBo">暂无审核数据</td>
            <td class="conBo">暂无审核数据</td>
            <td class="conBo">暂无审核数据</td>
          </tr>
        </table>
      </div>
    </Modal>

    <Card style="margin-left: 200px">
      <div style="height: 600px">
        <!-- <div class="titles">商品列表</div> -->
        <div class="title">
          <slot></slot>
        </div>
        <div class="content">
          <div id="contents">
            <div class="cont">
              <table>
                <tr>
                  <td
                    class="titNa"
                    v-for="(item, index) in titles"
                    :key="index"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr
                  v-for="(item2, index2) in contentC"
                  :key="index2"
                  :v-if="flag == 1"
                >
                  <td>
                    {{ item2.order_sn }}
                  </td>
                  <td>{{ item2.ctime }}</td>
                  <!-- <td>{{ item2.ctime }}</td> -->
                  <td>
                    {{ item2.su_name }}
                  </td>
                  <td>
                    {{ item2.store_name }}
                  </td>
                  <td>¥{{ item2.order_money }}</td>
                  <td>{{ item2.pay_type_name }}</td>
                  <td>{{ item2.order_type_name }}</td>
                  <td>{{ item2.order_status_name }}</td>
                  <td class="oper">
                    <div
                      class="stte"
                      @click="examine(item2.order_sn, item2.order_status)"
                    >
                      查看
                    </div>
                    <div class="close" @click="close(item2.orderid)">
                      关闭订单
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: "contents",
  props: {
    contentC: {
      type: Array,
    },
    titles: {
      type: Array,
    },
    flag: {
      type: Number,
    },
  },
  data() {
    return {
      social: [],
      dialogVisible: false,
      dialogVisibles: false,
      order_status: "",
      imgUrl: "",
      orderDetail: "",
      modal1: false,
      orderGoodsList: [],
      id: null,
      SHlist: [],
      state: null,
      form: {
        express_sn: "",
        express_name: "",
      },
    };
  },
  created() {
    this.imgUrl = this.$request.imgUrl2() + "uploads/";
  },
  components: {},
  methods: {
    audit(id) {
      //审核
      let token = this.$storage.getLocal("token");
      this.$request.selGoodsReviewDataList({ token, id }).then((res) => {
        if (res.code == 0) {
          this.SHlist = res.data.list;
          this.modal1 = true;
          this.id = id;
          this.state = res.data.is_gtype;
        } else {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    ok() {
      console.log("ok");
    },
    repetition() {
      let token = this.$storage.getLocal("token");
      this.$request.setGoodsReviewType({ token, id: this.id }).then((res) => {
        if (res.code == 0) {
          this.$notify({
            title: "成功",
            message: "已经重新提交审核",
            type: "success",
          });
          this.modal1 = false;
          this.$emit("del", "ok");
        } else {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
        }
      });
    },

    start(id, start) {
      let token = this.$storage.getLocal("token");
      this.$request
        .goodsIsGrounding({
          token,
          id,
          grounding: start == 2 ? 1 : 2,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "成功",
              message: "成功",
              type: "success",
            });
            this.$emit("del", "ok");
          } else {
            this.$notify({
              title: "警告",
              message: "失败",
              type: "warning",
            });
          }
        });
    },

    close(orderid) {
      //删除
      this.$confirm("此操作将关闭该订单,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let token = this.$storage.getLocal("token");
          this.$request
            .closeOrder({
              order_id: orderid,
              token,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$emit("close", "ok");
              } else {
                this.$emit("close", "ok");
                this.$notify({
                  title: "失败",
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    examine(id, order_status) {
      //查看

      this.dialogVisible = true;
      this.order_status = order_status;
      console.log(id);
      let token = this.$storage.getLocal("token");
      this.$request
        .orderInfo({
          token,
          order_sn: id,
        })
        .then((res) => {
          console.log(res);
          this.orderDetail = res.data;
          this.orderGoodsList = res.data.orderGoodsList;
        });
    },
    fahuo(e) {
      this.dialogVisibles = true;
      this.dialogVisible = false;
    },
    fahuos(e) {
      // express_sn:'',
      // express_name:''
      if (this.form.express_name == "") {
        this.$message.error("快递公司必选！");
      }
      if (this.form.express_sn == "") {
        this.$message.error("快递单号必填！");
      }
      let token = this.$storage.getLocal("token");
      this.$request
        .confirmDeliverGoods({
          token,
          order_sn: e,
          express_sn: this.form.express_sn,
          express_name: this.form.express_name,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "发货成功",
              type: "success",
            });
            this.dialogVisibles = false;
            this.dialogVisible = false;
            this.reload();
          } else {
            this.$message.error("发货失败");
          }
        });
    },
    log(id) {
      //日志
      console.log(id);
    },
    compile(id) {
      //编辑
      this.$router.push(
        "/addCommodity?id=" + id,
        function () {},
        function () {}
      );
    },
  },
};
</script>

<style scoped="scoped">
img {
  width: 20px;
}
table {
  width: 100%;
  background: #eeeeee;
}

td {
  background-color: #ffffff;
  font-size: 15px;
  height: 30px;
  /* width: 15%; */
}

tr .titNa {
  background-color: #f9fafc;
  font-size: 15px;
  font-weight: 800;
}
.titNa {
  text-align: center;
}
.conBo {
  text-align: center;
}
.oper {
  display: flex;
  justify-content: space-around;
  color: #42b983;
}
.shenhe {
  color: #42b983;
}
</style>
