<template>
	<div class="tabbar">
		<RadioGroup v-model="animal">
			<Radio v-for='item,index in tabbar' :label="item" border></Radio>
		</RadioGroup>
	</div>
</template>

<script>
	export default {
		name: 'tabbar',
		props: {
			tabbar: {
				type: Array
			}
		},
		watch:{
			animal(newValue,oldValue){
				this.$emit('cuts', newValue);
			}
		},
		data() {
			return {
				animal: '全部订单'
			}
		},
		created() {},
		components: {

		},
		methods: {
			
		}
	}
</script>

<style scoped="scoped">
	.tabbar{
		padding: 20px 0 20px 210px;
		text-align: left;
		/* justify-content: left; */
	}
</style>
